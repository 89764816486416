











import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { AddressInput, Dropdown, EmailInput, ErrorBox, PhoneInput, SaveView, STErrorsDefault, STInputBox, Validator } from "@stamhoofd/components";
import { Address } from "@stamhoofd/structures";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { MemberManager } from '../../../classes/MemberManager';

@Component({
    components: {
        SaveView,
        STErrorsDefault,
        STInputBox,
        PhoneInput,
        EmailInput,
        AddressInput,
        Dropdown
    }
})
export default class AddressView extends Mixins(NavigationMixin) {
    @Prop({ required: true})
        address!: Address
    errorBox: ErrorBox | null = null

    editAddress = Address.create(this.address)
    validator = new Validator()
    loading = false

    MemberManager = MemberManager
    
    @Prop({ required: true })
        handler: (parent: Address, component: AddressView) => Promise<void>|undefined;


    modifyAddress({ from, to }: { from: Address, to: Address }) {
        MemberManager.updateAddress(from, to)
    }

    async save() {
        if (this.loading) {
            return;
        }

        this.loading = true;
        const valid = await this.validator.validate()

        if (!valid) {
            this.loading = false;
            return;
        }

        MemberManager.updateAddress(this.address, this.editAddress)

        try {
            await this.handler(this.editAddress, this)
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false;
    }
}
