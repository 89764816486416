

















































import { Component,Prop,Vue } from "vue-property-decorator";

import BackButton from "./BackButton.vue";
import LoadingButton from "./LoadingButton.vue";
import STButtonToolbar from "./STButtonToolbar.vue";
import STNavigationBar from "./STNavigationBar.vue";
import STToolbar from "./STToolbar.vue";

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        LoadingButton,
        BackButton,
        STButtonToolbar
    }
})
export default class SaveView extends Vue {
    @Prop({ default: false })
    loading!: boolean;

    @Prop({ default: false })
    disabled!: boolean;

    @Prop({ default: "" })
    title!: string;

    @Prop({ default: "Opslaan" })
    saveText!: string;

    @Prop({ default: null })
    saveIcon!: string | null;

    @Prop({ default: null })
    saveIconRight!: string | null;

    @Prop({ default: "Annuleren" })
    cancelText!: string | null;

    @Prop({ default: false })
    preferLargeButton!: boolean; // Always use large buttons at the bottom on mobile

    @Prop({ default: false })
    addExtraCancel!: boolean; // Add a large cancel button at the bottom
}
