













import { Component, Prop,Vue } from "vue-property-decorator";

import { ErrorBox } from '../errors/ErrorBox';
import STErrorsInput from '../errors/STErrorsInput.vue';

@Component({
    components: {
        STErrorsInput
    }
})
export default class STInputBox extends Vue {
    @Prop({ default: ""}) errorFields: string;
    @Prop({ default: null }) errorBox: ErrorBox | null;

    @Prop({ default: false})
    indent!: boolean

    @Prop({ default: ""})
    title!: string
}
