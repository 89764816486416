










































import { DarkMode, Image, OrganizationMetaData, WebshopMetaData } from "@stamhoofd/structures";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OrganizationLogo extends Vue {
    @Prop({ required: true })
        metaData!: OrganizationMetaData | WebshopMetaData

    @Prop({ required: true })
        name!: string

    created() {
        // Inject favicon if no favicon is present
        if (!document.querySelector("link[rel='icon']")) {
            const resolution = this.squareLogo?.getResolutionForSize(256, 256);

            if (resolution && resolution.width === resolution.height) {
                const path = resolution.file.getPublicPath();
                const link = document.createElement("link");
                link.rel = "icon";
                link.href = path;
                link.type = path.endsWith('.png') ? "image/png" : (path.endsWith('.svg') ? "image/svg+xml" : "image/jpeg");
                document.head.appendChild(link);
            }
        }
    }

    get expand() {
        return this.metaData.expandLogo
    }

    get darkMode() {
        return (this.metaData as any).darkMode ?? DarkMode.Off
    }

    get horizontalLogo() {
        return this.metaData.horizontalLogo ?? this.metaData.horizontalLogoDark;
    }

    get horizontalLogoDark() {
        return this.metaData.horizontalLogoDark ?? this.metaData.horizontalLogo;
    }

    get squareLogo() {
        return this.metaData.squareLogo ?? this.metaData.squareLogoDark
    }

    get squareLogoDark() {
        return this.metaData.squareLogoDark ?? this.metaData.squareLogo
    }

    get logoSrc() {
        if (!this.metaData.squareLogo) {
            return null
        }
        if (this.metaData.expandLogo) {
            return this.metaData.squareLogo.getPathForSize(100, 70)
        }
        return this.metaData.squareLogo.getPathForSize(70, 50)
    }

    get logoHorizontalSrc() {
        if (!this.metaData.horizontalLogo) {
            return null
        }
        if (this.metaData.expandLogo) {
            return this.metaData.horizontalLogo.getPathForSize(undefined, 70)
        }
        return this.metaData.horizontalLogo.getPathForSize(150, 50)
    }

    getHorizontalResolution(image: Image) {
        if (this.expand) {
            return image.getResolutionForSize(undefined, 70)
        }
        return image.getResolutionForSize(150, 50)
    }

    getResolution(image: Image) {
        if (this.expand) {
            return image.getResolutionForSize(100, 70)
        }
        return image.getResolutionForSize(70, 50)
    }

    logoHorizontalSrcSet(image) {
        if (this.expand) {
            return image.getPathForSize(undefined, 70) + " 1x, "+image.getPathForSize(undefined, 70*2)+" 2x, "+image.getPathForSize(undefined, 70*3)+" 3x"
        }
        return image.getPathForSize(150, 50) + " 1x, "+image.getPathForSize(150*2, 50*2)+" 2x, "+image.getPathForSize(150*3, 50*3)+" 3x"
    }

    logoSrcSet(image) {
        if (this.expand) {
            return image.getPathForSize(100, 70) + " 1x, "+image.getPathForSize(100*2, 70*2)+" 2x, "+image.getPathForSize(100*3, 70*3)+" 3x"
        }
        return image.getPathForSize(70, 50) + " 1x, "+image.getPathForSize(70*2, 50*2)+" 2x, "+image.getPathForSize(70*3, 50*3)+" 3x"
    }
}
