
































import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { ErrorBox, Logo, STErrorsDefault } from "@stamhoofd/components"
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        Logo,
        STErrorsDefault
    }
})
export default class InvalidOrganizationView extends Mixins(NavigationMixin){
    @Prop({ default: null })
        errorBox: ErrorBox | null
}
