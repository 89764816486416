








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class StepperInput extends Vue {
    /** Price in cents */
    @Prop({ default: 0 })
        min!: number | null

    /** Price in cents */
    @Prop({ default: null })
        max!: number | null

    valid = true;

    /** Price in cents */
    @Prop({ default: 0 })
        value!: number

    get internalValue() {
        return this.value
    }

    set internalValue(val: number) {
        this.$emit("input", val)
    }

    // Limit value to bounds
    constrain(value: number): number {
        if (this.min !== null && value < this.min) {
            value = this.min;
        } else if (this.max !== null && value > this.max) {
            value = this.max;
        }
        return value
    }

    step(add: number) {
        if (!this.valid) {
            return;
        }
        const v = this.constrain(this.internalValue + add);
        this.internalValue = v
    }
}
