
























import { Component, Prop,Vue } from "vue-property-decorator";

import BackButton from ".//BackButton.vue";

@Component({
    components: {
        BackButton
    }
})
export default class STNavigationBar extends Vue {
    @Prop({ default: "", type: String })
        title!: string;

    @Prop({ default: false, type: Boolean })
        sticky!: boolean;

    @Prop({ default: true, type: Boolean })
        addShadow!: boolean;

    /**
     * Also show the title when not scrolled
     */
    @Prop({ default: false, type: Boolean })
        showTitle!: boolean;

    @Prop({ default: false, type: Boolean })
        fixed!: boolean;

    @Prop({ default: false, type: Boolean })
        large!: boolean;

    /// Add dismiss button (location depending on the OS)
    @Prop({ default: false, type: Boolean })
        dismiss!: boolean;

    /// Add pop button (location depending on the OS)
    @Prop({ default: false, type: Boolean })
        pop!: boolean;

    scrolled = false;
    scrollElement!: HTMLElement | null;

    $slots;

    get hasLeft() {
        return this.pop || (this.dismiss && (this as any).$isAndroid) || !!this.$slots['left']
    }

    get hasRight() {
        return (this.dismiss && !(this as any).$isAndroid) || !!this.$slots['right']
    }

    get hasMiddle() {
        return !!this.$slots['middle'] || this.title.length > 0
    }

    get templateColumns() {
        if (this.hasMiddle && (this.hasLeft || this.hasRight)) {
            if ((this as any).$isAndroid) {
                return "auto 1fr auto"
            }
            return "1fr auto 1fr"
        }

        if (!this.hasMiddle) {
            return "auto 1fr"
        }

        return "1fr"
    }


    getScrollElement(element: HTMLElement | null = null): HTMLElement {
        // If we are in modern mode, always choose the main element, which is the next sibling
        if (!this.sticky && document.body.className.indexOf("modern") > -1) {
            return this.$el.nextElementSibling as HTMLElement;
        }

        if (!element) {
            element = this.$el as HTMLElement;
        }

        const style = window.getComputedStyle(element);
        if (
            style.overflowY == "scroll" ||
            style.overflow == "scroll" ||
            style.overflow == "auto" ||
            style.overflowY == "auto"
        ) {
            return element;
        } else {
            if (!element.parentElement) {
                return document.documentElement;
            }
            return this.getScrollElement(element.parentElement);
        }
    }

    addListener() {
        if (this.scrollElement) {
            return;
        }
        this.scrollElement = this.getScrollElement();
        if (this.scrollElement === document.documentElement) {
            window.addEventListener("scroll", this.onScroll, { passive: true });
        } else {
            this.scrollElement.addEventListener("scroll", this.onScroll, { passive: true });
        }
    }

    mounted() {
        this.addListener();
    }

    activated() {
        // fix for element not yet in dom
        window.setTimeout(() => {
            this.addListener();
        }, 500);
    }

    deactivated() {
        if (!this.scrollElement) {
            return;
        }
        if (this.scrollElement === document.documentElement) {
            window.removeEventListener("scroll", this.onScroll);
        } else {
            this.scrollElement.removeEventListener("scroll", this.onScroll);
        }
        this.scrollElement = null;
    }

    onScroll() {
        const scroll = this.scrollElement!.scrollTop;
        if (scroll > 20) {
            this.scrolled = true;
        } else if (scroll < 15) {
            this.scrolled = false;
        }
    }
}
