























import { Group, WaitingListType } from '@stamhoofd/structures';
import { Component, Prop,Vue } from "vue-property-decorator";

@Component({
    components: {},
})
export default class GroupTag extends Vue {
    @Prop({ required: true })
    group: Group
    
    get preRegistrations() {
        return this.group.activePreRegistrationDate !== null
    }

    get waitingListIfFull() {
        return this.group.settings.waitingListIfFull
    }

    get allWaitingList() {
        return this.group.settings.waitingListType === WaitingListType.All
    }

    get newWaitingList() {
        return this.group.settings.waitingListType === WaitingListType.ExistingMembersFirst
    }
}
