




























import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { RecordAnswer, RecordCategory, RecordSettings, RecordType } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Prop } from "vue-property-decorator";

import { default as CopyableDirective } from "../directives/Copyable";

@Component({
    directives: { Copyable: CopyableDirective },
    filters: {
        price: Formatter.price
    }
})
export default class RecordCategoryAnswersBox extends Mixins(NavigationMixin){
    @Prop({ required: true })
    answers: RecordAnswer[]

    @Prop({ required: false })
    category?: RecordCategory

    @Prop({ required: true })
    dataPermission!: boolean

    @Prop({ default: false })
    canDelete!: boolean

    get records() {
        if (!this.category) {
            return this.answers.map(a => a.settings)
        }
        return this.category.filterRecords(this.dataPermission)
    }

    get RecordType() {
        return RecordType
    }

    getAnswer(record: RecordSettings) {
        return this.answers.find(a => a.settings.id === record.id)
    }
}
