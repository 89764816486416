
























































































































































































































import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { BackButton,Checkbox, RecordCategoryAnswersBox,STList, STListItem, STNavigationBar, STToolbar, TooltipDirective as Tooltip } from "@stamhoofd/components"
import { MemberDetails, MemberDetailsWithGroups, MemberWithRegistrations, RecordCategory, Registration } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CheckoutManager } from "../../classes/CheckoutManager";
import { OrganizationManager } from "../../classes/OrganizationManager";
import GroupTree from "../../components/GroupTree.vue";
import { BuiltInEditMemberStep, EditMemberStep, EditMemberStepsManager, EditMemberStepType, RecordCategoryStep } from "./details/EditMemberStepsManager";
import MemberChooseGroupsView from "./MemberChooseGroupsView.vue";

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        Checkbox,
        BackButton,
        GroupTree,
        RecordCategoryAnswersBox
    },
    filters: {
        price: Formatter.price,
        dateTime: Formatter.dateTime.bind(Formatter)
    },
    directives: { Tooltip },
})
export default class MemberView extends Mixins(NavigationMixin){
    @Prop({ required: true })
        member!: MemberWithRegistrations

    async editGeneral() {
        await this.openSteps(
            [
                new BuiltInEditMemberStep(EditMemberStepType.Details, true, false), 
                new BuiltInEditMemberStep(EditMemberStepType.Parents, true, false)
            ],
            false
        )
    }

    async editParents() {
        await this.openSteps([
            new BuiltInEditMemberStep(EditMemberStepType.Parents, true, false)
        ])
    }

    async editEmergencyContact() {
        await this.openSteps([
            new BuiltInEditMemberStep(EditMemberStepType.EmergencyContact, true, false)
        ])
    }

    async editRecordCategory(category: RecordCategory) {
        await this.openSteps([
            new RecordCategoryStep(category, true, false)
        ])
    }

    get recordCategories(): RecordCategory[] {
        const definitions = MemberDetailsWithGroups.getFilterDefinitions(OrganizationManager.organization, { member: this.member, registerItems: this.cartItems })

        return RecordCategory.filterCategories(
            OrganizationManager.organization.meta.recordsConfiguration.recordCategories, 
            new MemberDetailsWithGroups(this.member.details, this.member, this.cartItems), 
            definitions,
            this.dataPermission
        ).flatMap(cat => {
            if (cat.childCategories.length > 0) {
                return cat.filterChildCategories(
                    new MemberDetailsWithGroups(this.member.details, this.member, this.cartItems), 
                    definitions,
                    this.dataPermission
                )
            }
            return [cat]
        })
    }

    get dataPermission() {
        return this.member.details.dataPermissions?.value ?? false
    }

    async fullCheck() {
        const items = CheckoutManager.cart.items.filter(item => item.memberId === this.member.id)
        await this.openSteps(EditMemberStepsManager.getAllSteps(items, this.member, true, false), false)
    }

    async openSteps(steps: EditMemberStep[], force = true, lastSaveHandler?: (details: MemberDetails) => Promise<void>) {
        const stepManager = new EditMemberStepsManager(
            steps, 
            this.cartItems,
            this.member,
            async (component: NavigationMixin) => {
                component.dismiss({ force: true })
                return Promise.resolve()
            }
        )
        stepManager.force = force
        stepManager.lastSaveHandler = lastSaveHandler
        const component = await stepManager.getFirstComponent()

        if (!component) {
            // Weird
        } else {
            this.present(new ComponentWithProperties(NavigationController, {
                root: component
            }).setDisplayStyle("popup"))
        }
    }

    get parents() {
        return this.member.details?.parents ?? []
    }

    getGroup(groupId: string) {
        return OrganizationManager.organization.groups.find(g => g.id === groupId)
    }

    chooseGroups() {
        this.show({
            components: [
                new ComponentWithProperties(MemberChooseGroupsView, {
                    member: this.member
                })
            ]
        })
    }

    get cartItems() {
        return CheckoutManager.cart.items.filter(i => i.member.id === this.member.id)
    }

    imageSrc(registration: Registration) {
        const group = this.getGroup(registration.groupId)
        if (!group) {
            return null
        }
        return (group.settings.squarePhoto ?? group.settings.coverPhoto)?.getPathForSize(100, 100)
    }
}
