
















import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Checkbox,LoadingView, STList, STListItem, STNavigationBar, STToolbar, TooltipDirective } from "@stamhoofd/components"
import { GroupCategoryTree } from '@stamhoofd/structures';
import { Component, Mixins, Prop } from "vue-property-decorator";

import GroupBox from "./GroupBox.vue"

@Component({
    name: "GroupTree", // required for recursive component + minification of vue
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        LoadingView,
        Checkbox,
        GroupBox
    },
    directives: {
        tooltip: TooltipDirective
    }
})
export default class GroupTree extends Mixins(NavigationMixin){
    @Prop({ required: true })
    category: GroupCategoryTree

    @Prop({ default: true })
    isLast!: boolean

    @Prop({ default: -1 })
    parentLevel!: number

    get level() {
        return Math.min(this.parentLevel + 1, 2)
    }
}
