











































import { SimpleError, SimpleErrors } from '@simonbackx/simple-errors';
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Dropdown, EmailInput, ErrorBox, PhoneInput, SaveView, SelectionAddressInput, STErrorsDefault, STInputBox, Validator } from "@stamhoofd/components";
import { Address, MemberDetails, Parent, ParentType, ParentTypeHelper } from "@stamhoofd/structures";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { MemberManager } from '../../../classes/MemberManager';

@Component({
    components: {
        SaveView,
        STErrorsDefault,
        STInputBox,
        PhoneInput,
        EmailInput,
        SelectionAddressInput,
        Dropdown
    }
})
export default class ParentView extends Mixins(NavigationMixin) {
    @Prop({ default: null })
        memberDetails: MemberDetails | null

    @Prop({ default: null })
        parent: Parent | null

    @Prop({ required: true })
        handler: (parent: Parent, component: ParentView) => Promise<void>|undefined;

    loading = false

    firstName = ""
    lastName = ""
    type: ParentType = ParentType.Mother
    phone: string | null = null
    email: string | null = null
    errorBox: ErrorBox | null = null

    address: Address | null = null

    validator = new Validator()

    MemberManager = MemberManager

    get hasAccess() {
        return this.memberDetails?.parentsHaveAccess ?? false
    }

    mounted() {
        if (this.parent) {
            this.firstName = this.parent.firstName
            this.lastName = this.parent.lastName
            this.phone = this.parent.phone
            this.email = this.parent.email
            this.address = this.parent.address ? Address.create(this.parent.address) : null
            this.type = this.parent.type
        }
    }

    get parentTypes() {
        return ParentTypeHelper.getPublicTypes()
    }

    parentTypeName(type: ParentType) {
        return ParentTypeHelper.getName(type)
    }

    get availableAddresses() {
        const addresses = MemberManager.getAddresses()
        if (this.memberDetails) {
            for (const parent of this.memberDetails.parents) {
                if (parent.address && !addresses.find(a => a.toString() == parent.address!.toString())) {
                    addresses.push(parent.address)
                }
            }
        }
        return addresses
    }

    modifyAddress({ from, to }: { from: Address, to: Address }) {
        MemberManager.updateAddress(from, to)
        if (this.memberDetails) {
            this.memberDetails.updateAddress(from, to)
        }
    }

    async goNext() {
        if (this.loading) {
            return;
        }

        const errors = new SimpleErrors()
        if (this.firstName.length < 2) {
            errors.addError(new SimpleError({
                code: "invalid_field",
                message: "Vul de voornaam in",
                field: "firstName"
            }))
        }
        if (this.lastName.length < 2) {
            errors.addError(new SimpleError({
                code: "invalid_field",
                message: "Vul de achternaam in",
                field: "lastName"
            }))
        }

        this.loading = true
        try {
            const valid = await this.validator.validate()

            if (errors.errors.length > 0) {
                this.loading = false
                this.errorBox = new ErrorBox(errors)
                return;
            } 

            if (!valid) {
                this.loading = false
                this.errorBox = null
                return;
            }

        } catch (e) {
            this.loading = false
            this.errorBox = new ErrorBox(e)
            return;
        }
       
        if (this.parent) {
            this.parent.firstName = this.firstName
            this.parent.lastName = this.lastName
            this.parent.phone = this.phone
            this.parent.email = this.email
            this.parent.address = this.address
            this.parent.type = this.type
            MemberManager.updateParent(this.parent)
            if (this.memberDetails) {
                this.memberDetails.updateParent(this.parent)
            }
        } else {
            this.parent = Parent.create({
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                email: this.email,
                address: this.address,
                type: this.type
            })
        }
        
        try {
            await this.handler(this.parent, this)
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }
}
