















































import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Checkbox,STErrorsDefault, STList, STListItem, STNavigationBar, STToolbar } from "@stamhoofd/components";
import { BalanceItemCartItem, MemberBalanceItem } from "@stamhoofd/structures";
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CheckoutManager } from "../../classes/CheckoutManager";
import { MemberManager } from '../../classes/MemberManager';
import { OrganizationManager } from '../../classes/OrganizationManager';
import CartView from "../checkout/CartView.vue";

@Component({
    components: {
        STList,
        STListItem,
        STErrorsDefault,
        STNavigationBar,
        STToolbar,
        Checkbox
    },
    filters: {
        price: Formatter.price
    }
})
export default class AddBalanceItemsToCartView extends Mixins(NavigationMixin){
    MemberManager = MemberManager

    @Prop(({required: true}))
        balanceItems: MemberBalanceItem[]

    selectedItems: BalanceItemCartItem[] = []

    created() {
        // Select all
        this.selectedItems = this.balanceItems.flatMap(item => {
            const price = MemberBalanceItem.getOutstandingBalance([item]).totalOpen
            if (price === 0) {
                return [];
            }
            return [
                BalanceItemCartItem.create({
                    item,
                    price
                })
            ]
        })
    }

    get multipleMembers() {
        return (MemberManager.members?.length ?? 0) > 1
    }

    getMember(memberId: string) {
        return MemberManager.members?.find(m => m.id === memberId)
    }

    get outstandingItems() {
        return this.balanceItems.filter(i => !i.isPaid)
    }
    
    get organization() {
        return OrganizationManager.organization
    }    

    formatDate(date: Date) {
        return Formatter.date(date, true)
    }

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    isItemSelected(item: MemberBalanceItem) {
        return !!this.selectedItems.find(s => s.item.id === item.id && s.price !== 0)
    }

    get totalSelected() {
        return this.selectedItems.reduce((a, b) => a + b.price, 0)
    }

    setItemSelected(item: MemberBalanceItem, selected: boolean) {
        if (selected === this.isItemSelected(item)) {
            return
        }
        const arr = this.selectedItems.filter(s => s.item.id !== item.id)
        if (selected) {
            const price = MemberBalanceItem.getOutstandingBalance([item]).totalOpen
            if (price === 0) {
                return;
            }
            arr.push(BalanceItemCartItem.create({
                item,
                price
            }))
            this.selectedItems = arr
        } else {
            this.selectedItems = arr
        }
    }

    startPayment() {
        for (const item of this.selectedItems) {
            CheckoutManager.cart.addBalanceItem(item)
        }
        CheckoutManager.saveCart()
        this.show({
            components: [
                new ComponentWithProperties(CartView, {})
            ],
            replace: this.navigationController?.components?.length ?? 0
        })
    }
}
