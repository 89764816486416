

















































import { isSimpleError, isSimpleErrors, SimpleError } from '@simonbackx/simple-errors';
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { CenteredMessage, Checkbox,ConfirmEmailView,EmailInput, ErrorBox, LoadingButton, PasswordStrength,STErrorsDefault, STInputBox, STNavigationBar, STToolbar, Validator } from "@stamhoofd/components"
import { LoginHelper, Session, SessionManager } from '@stamhoofd/networking';
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";

import { OrganizationManager } from '../../classes/OrganizationManager';

// The header component detects if the user scrolled past the header position and adds a background gradient in an animation
@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STInputBox,
        LoadingButton,
        STErrorsDefault,
        EmailInput,
        Checkbox,
        PasswordStrength
    }
})
export default class SignupView extends Mixins(NavigationMixin){
    loading = false;
    
    @Prop({ default: ""})
        initialEmail!: string

    @Prop({ default: null})
        lock!: string | null

    email = this.initialEmail
    password = ""
    passwordRepeat = ""
    acceptPrivacy = false

    errorBox: ErrorBox | null = null
    validator = new Validator()

    session = SessionManager.currentSession!

    @Ref("emailInput")
        emailInput: EmailInput

    get privacyUrl() {
        if (OrganizationManager.organization.meta.privacyPolicyUrl) {
            return OrganizationManager.organization.meta.privacyPolicyUrl
        }
        if (OrganizationManager.organization.meta.privacyPolicyFile) {
            return OrganizationManager.organization.meta.privacyPolicyFile.getPublicPath()
        }
        return null
    }

    async submit() {
        if (this.loading) {
            return
        }       

        const valid = await this.validator.validate()

        if (this.password != this.passwordRepeat) {
            this.errorBox = new ErrorBox(new SimpleError({
                code: "",
                message: "De ingevoerde wachtwoorden komen niet overeen"
            }))
            return;
        }

        if (this.password.length < 8) {
            this.errorBox = new ErrorBox(new SimpleError({
                code: "",
                message: "Jouw wachtwoord moet uit minstens 8 karakters bestaan."
            }))
            return;
        }

        if (!this.acceptPrivacy && !!this.privacyUrl) {
            this.errorBox = new ErrorBox(new SimpleError({
                code: "read_privacy",
                message: "Je moet kennis hebben genomen van het privacybeleid voor je een account kan aanmaken."
            }))
            return;
        }

        if (!valid) {
            this.errorBox = null
            return;
        }

        this.loading = true
        this.errorBox = null
        
        // Request the key constants
        try {
            const session = new Session(OrganizationManager.organization.id)
            session.organization = OrganizationManager.organization

            const token = await LoginHelper.signUp(session, this.email, this.password)
            
            this.loading = false;

            this.show(new ComponentWithProperties(ConfirmEmailView, { token, session, email: this.email }))
            return
            
        } catch (e) {
            console.log(e)
            this.loading = false;

            if (isSimpleError(e) || isSimpleErrors(e)) {
                this.errorBox = new ErrorBox(e)
                return;
            }

            new CenteredMessage("Er ging iets mis", "Het is niet gelukt om een account aan te maken. Probeer het op een ander toestel of browser opnieuw uit of neem contact met ons op.", "error").addCloseButton().show()
            return;
        }
        
    }

    mounted() {
        setTimeout(() => {
            // Needed the any here because typescript is getting mad only in production mode
            if (this.emailInput) {
                (this.emailInput as any).focus()
            }
        }, 300);
    }
}
