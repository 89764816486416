



















import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class STErrorBox extends Vue {
    beforeEnter(el: HTMLElement) {
        el.style.opacity = "0";
    }

    enter(el: HTMLElement) {
        const height = el.offsetHeight;
        el.style.height = "0";

        requestAnimationFrame(() => {
            el.style.height = height+"px";
            el.style.opacity = "1";
        });

    }

    afterEnter(el: HTMLElement) {
        el.style.height = "";
    }


    leave(el: HTMLElement) {
        const height = el.offsetHeight;
        el.style.height = height+"px";

        requestAnimationFrame(() => {
            el.style.height = "0px";
            el.style.opacity = "0";
        });

    }
}
