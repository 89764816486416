























import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Checkbox,LoadingView, STList, STListItem, STNavigationBar, STToolbar } from "@stamhoofd/components"
import { Group, WaitingListType } from '@stamhoofd/structures';
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CheckoutManager } from "../classes/CheckoutManager";
import GroupView from "../views/groups/GroupView.vue";
import GroupTag from "./GroupTag.vue";

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        LoadingView,
        Checkbox,
        GroupTag
    }
})
export default class GroupBox extends Mixins(NavigationMixin){
    @Prop({ required: true })
    group: Group

    CheckoutManager = CheckoutManager
    
    
    get imageSrc() {
        return (this.group.settings.squarePhoto ?? this.group.settings.coverPhoto)?.getPathForSize(100, 100)
    }

    get selectedCount() {
        return CheckoutManager.cart.items.filter(i => i.group.id === this.group.id).length
    }

    get preRegistrations() {
        return this.group.activePreRegistrationDate !== null
    }

    get waitingListIfFull() {
        return this.group.settings.waitingListIfFull
    }

    get allWaitingList() {
        return this.group.settings.waitingListType === WaitingListType.All
    }

    get newWaitingList() {
        return this.group.settings.waitingListType === WaitingListType.ExistingMembersFirst
    }

    onClicked() {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(GroupView, {
                group: this.group
            })
        }).setDisplayStyle("popup"))
    }

}
