










import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Spinner from "../Spinner.vue";

@Component({
    components: {Spinner}
})
export default class LoadingButton extends Vue {
    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    // Remove the spinner animation from the dom to save some resources of the browser
    delayLoading = false

    @Watch('loading')
    onValueChanged(val: boolean, old: boolean) {
        if (!val && old) {
            this.delayLoading = true
            setTimeout(() => {
                this.delayLoading = false
            }, 300)
        }
    }
}
