

















import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins,Prop } from "vue-property-decorator";

import Spinner from '../Spinner.vue';
import { Toast } from './Toast';

/**
 * This component will automatically show the root if we have a valid token. If the user logs out, we'll automatically show the login view
*/
@Component({
    components: {
        Spinner
    }
})
export default class ToastView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    toast: Toast

    get message() {
        return this.toast.message
    }

    isClosing = false

    @Prop({default: null})
    onClose: (() => void) | null

    mounted() {
        if (this.toast.autohideAfter) {
            window.setTimeout(() => {
                this.close();
            }, this.toast.autohideAfter)
        }
        this.toast.doHide = () => {
            this.close()
        }
    }

    clicked() {
        this.close();
        if (this.toast.action) {
            this.toast.action()
        }
    }

    close() {
        if (this.onClose && !this.isClosing) {
            this.onClose();
        }
        this.isClosing = true
        this.emitParents("pop", undefined);
    }

    clickedButton() {
        this.toast.button!.action()
        this.close()
    }
}
