
































































import { ComponentWithProperties, NavigationController, NavigationMixin, PushOptions } from "@simonbackx/vue-app-navigation";
import { CenteredMessage, CenteredMessageView, ForgotPasswordResetView, ForgotPasswordView, LegalFooter,LoadingButton, ModalStackEventBus, OrganizationLogo, STFloatingFooter, STInputBox, STNavigationBar } from "@stamhoofd/components";
import { SessionManager, UrlHelper } from '@stamhoofd/networking';
import { Component, Mixins } from "vue-property-decorator";

import { OrganizationManager } from '../../classes/OrganizationManager';
import GroupTree from '../../components/GroupTree.vue';
import LoginView from './LoginView.vue';
import SignupView from './SignupView.vue';

// The header component detects if the user scrolled past the header position and adds a background gradient in an animation
@Component({
    components: {
        STNavigationBar,
        STFloatingFooter,
        STInputBox,
        LoadingButton,
        OrganizationLogo,
        GroupTree,
        LegalFooter
    },
    metaInfo() {
        return {
            title: "Inschrijven bij "+OrganizationManager.organization.name,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Via deze website kan je jouw inschrijvingen beheren.",
                },
                {
                    hid: 'og:site_name',
                    name: 'og:site_name',
                    content: OrganizationManager.organization.name,
                },
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: "Inschrijven bij "+OrganizationManager.organization.name,
                },
                ...(this.firstImageResolution ? [
                    {
                        hid: 'og:image',
                        name: 'og:image',
                        content: this.firstImageResolution.file.getPublicPath()
                    },
                    {
                        hid: 'og:image:width',
                        name: 'og:image:width',
                        content: this.firstImageResolution.width
                    },
                    {
                        hid: 'og:image:height',
                        name: 'og:image:height',
                        content: this.firstImageResolution.height
                    },
                    {
                        hid: 'og:image:type',
                        name: 'og:image:type',
                        content: this.firstImageResolution.file.getPublicPath().endsWith(".png") ? 'image/png' : 'image/jpeg'
                    },
                ] : [])
            ]
        }
    }
})
export default class HomeView extends Mixins(NavigationMixin){
    loading = false;
    email = ""
    password = ""

    session = SessionManager.currentSession!

    mounted() {
        const parts =  UrlHelper.shared.getParts()
        const queryString =  UrlHelper.shared.getSearchParams()

        UrlHelper.setUrl("/")

        if (parts.length == 1 && parts[0] == 'reset-password') {
            UrlHelper.shared.clear()

            const token = queryString.get('token');
            this.present(new ComponentWithProperties(ForgotPasswordResetView, { token }).setDisplayStyle("popup"));
        }

        if (parts.length == 1 && parts[0] == 'login') {
            UrlHelper.shared.clear()

            const email = queryString.get('email')
            const hasAccount = queryString.get('hasAccount')

            if (email) {
                if (hasAccount === null || hasAccount === "1") {
                    this.login(false, email, "Dit adres is verbonden met jouw gegevens. Je kan jouw e-mailadres na het inloggen wijzigen. Gebruik de wachtwoord vergeten knop als je jouw wachtwoord niet meer weet.")
                } else {
                    this.createAccount(false, email, "Je kan jouw e-mailadres pas wijzigen nadat je een account hebt aangemaakt.")
                }
            }
        }

        ModalStackEventBus.addListener(this, "present", async (options: PushOptions | ComponentWithProperties) => {
            this.present(options);
            return Promise.resolve()
        })

        CenteredMessage.addListener(this, (centeredMessage) => {
            this.present(new ComponentWithProperties(CenteredMessageView, { centeredMessage }).setDisplayStyle("overlay"))
        })
    }

    get firstImage() {
        for (const group of this.organization.groups) {
            if (group.settings.coverPhoto) {
                return group.settings.coverPhoto
            }
        }
        return null
    }

    get firstImageResolution() {
        return this.firstImage?.getResolutionForSize(2000, 2000)
    }

    beforeDestroy() {
        CenteredMessage.removeListener(this)
    }

    get organization() {
        return OrganizationManager.organization
    }

    get rootCategory() {
        return this.organization.publicCategoryTree
    }

    get privacyUrl() {
        if (OrganizationManager.organization.meta.privacyPolicyUrl) {
            return OrganizationManager.organization.meta.privacyPolicyUrl
        }
        if (OrganizationManager.organization.meta.privacyPolicyFile) {
            return OrganizationManager.organization.meta.privacyPolicyFile.getPublicPath()
        }
        return null
    }

    returnToSite() {
        if (!this.organization.website || (!this.organization.website.startsWith("https://") && !this.organization.website.startsWith("http://"))) {
            return
        }
        window.location.href = this.organization.website
    }

    gotoPasswordForgot() {
        this.present(new ComponentWithProperties(ForgotPasswordView, {}).setDisplayStyle("sheet"))
    }

    login(animated = true, email = "", lock: null | string) {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(LoginView, {
                initialEmail: email,
                lock
            })
        }).setAnimated(animated).setDisplayStyle("sheet")) 
    }

    createAccount(animated = true, email = "", lock: null | string) {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(SignupView, {
                initialEmail: email,
                lock
            })
        }).setAnimated(animated).setDisplayStyle("popup")) 
    }
}
