













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class STToolbar extends Vue {
    /**
     * Set sticky to false if a user is required to scroll down before completing a flow (this removes the unneeded CTA in the middle of the flow).
     */
    @Prop({ default: true })
        sticky!: boolean
}
