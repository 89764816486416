
















import { ComponentWithProperties, ComponentWithPropertiesInstance } from "@simonbackx/vue-app-navigation";
import { Component, Vue } from "vue-property-decorator";

import { Toast } from "./Toast"
import ToastView from './ToastView.vue';
/**
 * This component will automatically show the root if we have a valid token. If the user logs out, we'll automatically show the login view
*/
@Component({
    components: {
        ComponentWithPropertiesInstance
    }
})
export default class ToastBox extends Vue {
    components: ComponentWithProperties[] = []
    lastOffset = false

    mounted() {
        Toast.addListener(this, this.showToast)
    }

    showToast(toast: Toast) {
        this.show(new ComponentWithProperties(ToastView, { toast }))
    }

    hide(tag: string) {
        for (const [index, component] of this.components.entries()) {
            if (component.properties.tags && Array.isArray(component.properties.tags) && component.properties.tags.includes(tag)) {
                this.removeAt(index, component.key)
            }
        }
    }

    show(component: ComponentWithProperties) {
        // Make sure this component is not counted in navigation stuff
        component.modalDisplayStyle = "overlay"

        // if mobile: add to beginning
        if (document.body.offsetWidth <= 450) {
            this.components.unshift(component);
        } else {
            this.components.push(component);
        }
    }

    removeAt(index, key) {
        if (this.components[index].key === key) {
            this.components.splice(index, 1);
        } else {
            console.warn("Expected component with key " + key + " at index" + index);
        }
    }

    beforeDestroy() {
        Toast.removeListener(this)
        this.components = [];
    }

    get withOffset() {
        if (this.components.length == 0) {
            return this.lastOffset
        }

        for (const [index, component] of this.components.entries()) {
            if (component.properties.toast && component.properties.toast.withOffset) {
                this.lastOffset = true;
                return true
            }
        }
        this.lastOffset = false;
        return false
    }
}
