































import { STInputBox } from "@stamhoofd/components"
import { Component, Prop,Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
        STInputBox
    }
})
export default class PasswordStrength extends Vue {
    @Prop({ default: null })
    value!: string | null

    strength = 0
    duration = 0
    warning = ""

    calculateCounter = 0
    loading = false

    @Watch('value')
    onValueChanged(val: string | null) {
        if (val === null || val.length == 0) {
            this.calculateCounter++
            this.strength = 0;
            this.duration = 0;
            this.loading = false
            return
        }
        this.calculateStrength(val).catch(e => {
            console.error(e)
        })
    }

    async calculateStrength(password: string) {
        this.calculateCounter++
        const saved = this.calculateCounter
        this.loading = true

        try {
            const calculator = await import(/* webpackChunkName: "PasswordStrengthCalculator" */ "./PasswordStrengthCalculator")
            if (saved != this.calculateCounter) {
                // skip
                return
            }
            const result = calculator.checkPassword(password)
            if (saved != this.calculateCounter) {
                // skip
                return
            }
            this.warning = result.feedback.warning ?? ""
            this.strength = result.score*25
            this.duration = result.crackTimesSeconds.offlineSlowHashing1e4PerSecond

        } catch (e) {
            // ignore
        }

        if (saved === this.calculateCounter) {
            this.loading = false
        }
    }

    get type() {
        const strength = this.strength
        if (strength == 0) {
            return "none"
        }

        if (strength < 50) {
            return "error"
        }

        if (strength < 100) {
            return "warning"
        }

        return "success"
    }

    get description() {
        const strength = this.strength
        if (strength < 50) {
            return "Heel zwak"
        }

        if (strength < 75) {
            return "Zwak"
        }

        if (strength < 100) {
            return "Matig"
        }

        return "Sterk"
    }

    get detailDescription() {
        if (this.warning.length > 0) {
            return this.warning
        }
    }

}
