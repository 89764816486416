







































































































































































































import { Decoder } from "@simonbackx/simple-encoding";
import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { CenteredMessage, LegalFooter,LoadingView, OrganizationLogo, PromiseView, Spinner, STList, STListItem, STNavigationBar, STToolbar, Toast } from "@stamhoofd/components";
import { downloadDocument } from "@stamhoofd/document-helper"
import { SessionManager, UrlHelper } from "@stamhoofd/networking";
import { Document, DocumentStatus, MemberWithRegistrations } from "@stamhoofd/structures";
import { MemberBalanceItem, Payment, PaymentStatus, PaymentWithRegistrations } from "@stamhoofd/structures";
import { Formatter } from "@stamhoofd/utility";
import { Component, Mixins } from "vue-property-decorator";

import { CheckoutManager } from "../../classes/CheckoutManager";
import { MemberManager } from "../../classes/MemberManager";
import { OrganizationManager } from "../../classes/OrganizationManager";
import { Suggestion, SuggestionBuilder } from "../../classes/SuggestionBuilder";
import AccountSettingsView from "../account/AccountSettingsView.vue";
import PaymentsView from "../account/PaymentsView.vue";
import CartView from "../checkout/CartView.vue";
import { createMemberComponent } from "../members/details/createMemberComponent";
import { EditMemberStepsManager } from "../members/details/EditMemberStepsManager";
import CheckDataView from "./CheckDataView.vue";
import ChooseMemberView from "./register-flow/ChooseMemberView.vue";

@Component({
    components: {
        STNavigationBar,
        OrganizationLogo,
        STList,
        STListItem,
        LoadingView,
        STToolbar,
        Spinner,
        LegalFooter
    }
})
export default class NewOverviewView extends Mixins(NavigationMixin){
    loadingBalance = false
    MemberManager = MemberManager
    CheckoutManager = CheckoutManager

    created() {
        this.updateCartAndBalance().catch(console.error)
    }

    get balanceItems() {
        return CheckoutManager.balanceItems ?? []
    }

    get isAcceptingNewMembers() {
        return this.organization.isAcceptingNewMembers(!!SessionManager.currentSession?.user?.permissions)
    }

    get isAcceptingExistingMembers() {
        return this.organization.isAcceptingExistingMembers(!!SessionManager.currentSession?.user?.permissions)
    }

    get documents() {
        return this.MemberManager.documents ?? []
    }

    mounted() {
        const parts =  UrlHelper.shared.getParts()
        const searchParams = UrlHelper.shared.getSearchParams()
        UrlHelper.setUrl("/")
        document.title = "Ledenportaal - " + this.organization.name

        if (parts.length >= 1 && parts[0] == 'cart') {
            if (parts.length === 1) {
                UrlHelper.shared.clear()
            }
            this.openCart(false)
        } else if (parts.length >= 1 && parts[0] == 'payments') {
            if (parts.length === 1) {
                UrlHelper.shared.clear()
            }
            this.managePayments(false)
        } else if (parts.length == 1 && parts[0] == 'payment' && searchParams.get("id")) {
            UrlHelper.shared.clear()

            const paymentId = searchParams.get("id")
            const cancel = searchParams.get("cancel") === "true"

            const session = SessionManager.currentSession!
            const component = new ComponentWithProperties(NavigationController, { 
                root: new ComponentWithProperties(PromiseView, {
                    promise: async () => {
                        const PaymentPendingView = (await import(/* webpackChunkName: "Checkout" */ "@stamhoofd/components/src/views/PaymentPendingView.vue")).default
                        return new ComponentWithProperties(PaymentPendingView, {
                            server: session.authenticatedServer,
                            paymentId,
                            cancel,
                            finishedHandler: async function(this: NavigationMixin, payment: Payment | null) {
                                if (payment && payment.status == PaymentStatus.Succeeded) {
                                    const RegistrationSuccessView = (await import(/* webpackChunkName: "Checkout" */ "../checkout/RegistrationSuccessView.vue")).default
                                    const response = await session.authenticatedServer.request({
                                        method: "GET",
                                        path: "/payments/"+payment.id+"/registrations",
                                        decoder: PaymentWithRegistrations as Decoder<PaymentWithRegistrations>
                                    })
                                    const registrations = response.data.registrations

                                    this.show({
                                        components: [
                                            new ComponentWithProperties(RegistrationSuccessView, {
                                                registrations
                                            })
                                        ], 
                                        replace: 1, 
                                        force: true
                                    })

                                } else {
                                    UrlHelper.setUrl("/")
                                    this.dismiss({ force: true })
                                    new CenteredMessage("Betaling mislukt", "De betaling werd niet voltooid of de bank heeft de betaling geweigerd. Probeer het opnieuw.").addCloseButton().show()
                                }
                            }
                        })
                    }
                })
            })
            this.present({
                components: [component],
                animated: false,
                modalDisplayStyle: "popup"
            })
        }
    }

    async updateCartAndBalance() {
        this.loadingBalance = true;
        try {
            await CheckoutManager.recalculateCart(false)
        } catch (e) {
            // Fail silently here
            console.error(e);
        }
        this.loadingBalance = false;
    }

    get notYetPaidBalance() {
        return MemberBalanceItem.getOutstandingBalance(this.balanceItems).totalOpen
    }

    get organization() {
        return OrganizationManager.organization
    }

    get members() {
        if (MemberManager.members) {
            return MemberManager.members
        }
        return []
    }

    get membersWithMissingData() {
        return this.members.filter(member => member.activeRegistrations.length && this.getStepsManagerMissingData(member).hasSteps())
    }

    getStepsManagerMissingData(member: MemberWithRegistrations) {
        const items = CheckoutManager.cart.items.filter(item => item.memberId === member.id)
        const steps = EditMemberStepsManager.getAllSteps(items, member, false, true)

        const stepManager = new EditMemberStepsManager(
            steps, 
            items,
            member,
            async (component: NavigationMixin) => {
                component.dismiss({ force: true })
                return Promise.resolve()
            }
        )
        return stepManager
    }

    async fillInMemberMissingData(member: MemberWithRegistrations) {
        const stepManager = this.getStepsManagerMissingData(member)
        const component = await stepManager.getFirstComponent()

        if (!component) {
            // Weird
        } else {
            this.present(new ComponentWithProperties(NavigationController, {
                root: component
            }).setDisplayStyle("popup"))
        }
    }

    get cart() {
        return CheckoutManager.cart
    }

    get suggestedRegistrations(): Suggestion[] {
        return SuggestionBuilder.getSuggestions(this.members)
    }

    startRegistrationFlow(suggestion: Suggestion) {
        this.present({
            components: [
                new ComponentWithProperties(NavigationController, {
                    root: suggestion.getComponent()
                })
            ],
            modalDisplayStyle: "popup"
        })
    }

    openCart(animated = true) {
        this.present({
            components: [
                new ComponentWithProperties(NavigationController, {
                    root: new ComponentWithProperties(CartView, {})
                })
            ],
            modalDisplayStyle: "popup",
            animated
        })
    }

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    async registerMember() {
        if (this.members.length == 0) {
            const component = await createMemberComponent()
            this.present({
                components: [
                    new ComponentWithProperties(
                        NavigationController,
                        {
                            root: component
                        }
                    )
                ],
                modalDisplayStyle: "popup"
            })
            return;
        }
        this.present({
            components: [
                new ComponentWithProperties(
                    NavigationController,
                    {
                        root: new ComponentWithProperties(ChooseMemberView, {})
                    }
                )
            ],
            modalDisplayStyle: "popup"
        })
    }

    checkData() {
        this.present({
            components: [
                new ComponentWithProperties(
                    NavigationController,
                    {
                        root: new ComponentWithProperties(CheckDataView, {})
                    }
                )
            ],
            modalDisplayStyle: "popup"
        })
    }

    manageAccount() {
        this.present({
            components: [
                new ComponentWithProperties(
                    NavigationController,
                    {
                        root: new ComponentWithProperties(AccountSettingsView, {})
                    }
                )
            ],
            modalDisplayStyle: "popup"
        })
    }

    managePayments(animated = true) {
        this.present({
            components: [
                new ComponentWithProperties(
                    NavigationController,
                    {
                        root: new ComponentWithProperties(PaymentsView, {})
                    }
                )
            ],
            modalDisplayStyle: "popup",
            animated
        })
    }

    downloadingDocuments: Document[] = []

    async downloadDocument(document: Document) {
        if (this.isDocumentDownloading(document)) {
            return
        }
        if (document.status === DocumentStatus.MissingData) {
            new Toast('Dit document kan niet gedownload worden omdat er nog gegevens ontbreken. Vul eerst alle ontbrekende gegevens aan en contacteer ons indien het probleem nog niet is verholpen.', 'error red').show()
            return
        }
        this.downloadingDocuments.push(document)
        try {
            await downloadDocument(document);
        } catch (e) {
            console.error(e);
        }
        this.downloadingDocuments = this.downloadingDocuments.filter(d => d.id != document.id)
    }

    isDocumentDownloading(document: Document) {
        return !!this.downloadingDocuments.find(d => d.id == document.id)
    }
}
