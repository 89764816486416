






















import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { BackButton,EmailInput, ErrorBox, LoadingButton, STErrorsDefault,STFloatingFooter, STNavigationBar, Toast, Validator } from "@stamhoofd/components"
import { Session,SessionManager } from '@stamhoofd/networking';
import { ForgotPasswordRequest } from '@stamhoofd/structures';
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        STNavigationBar,
        STFloatingFooter,
        EmailInput,
        LoadingButton,
        STErrorsDefault,
        BackButton
    }
})
export default class ForgotPasswordView extends Mixins(NavigationMixin){
    loading = false

    @Prop({ default: ""})
    initialEmail!: string

    @Prop({ default: false})
    isAdmin!: boolean

    email = this.initialEmail
    validator = new Validator()
    errorBox: ErrorBox | null = null

    @Prop({ default: () => SessionManager.currentSession })
    session!: Session

    async submit() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.errorBox = null;

        if (!(await this.validator.validate())) {
            this.loading = false;
            return;
        }

        try {
            const response = await this.session.server.request({
                method: "POST",
                path: "/forgot-password",
                body: ForgotPasswordRequest.create({ email: this.email }),
                shouldRetry: false
            })

            this.dismiss({ force: true })
            new Toast("Je hebt een e-mail ontvangen waarmee je een nieuw wachtwoord kan instellen", "success").show()
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }

        this.loading = false;
    }
}
