





































































































import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { BackButton, OrganizationLogo, STList, STListItem, STNavigationBar } from "@stamhoofd/components";
import { SessionManager } from "@stamhoofd/networking";
import { Address, MemberWithRegistrations, Parent } from "@stamhoofd/structures";
import { Component, Mixins } from "vue-property-decorator";

import { MemberManager } from "../../classes/MemberManager";
import { OrganizationManager } from "../../classes/OrganizationManager";
import AddressView from "../members/details/AddressView.vue";
import { createMemberComponent } from "../members/details/createMemberComponent";
import ParentView from "../members/details/ParentView.vue";
import MemberView from "../members/MemberView.vue";

@Component({
    components: {
        STNavigationBar,
        OrganizationLogo,
        STList,
        STListItem,
        BackButton
    }
})
export default class CheckDataView extends Mixins(NavigationMixin){
    MemberManager = MemberManager

    get organization() {
        return OrganizationManager.organization
    }

    get members() {
        if (MemberManager.members) {
            return MemberManager.members
        }
        return []
    }

    get parents() {
        return this.MemberManager.getParents()
    }

    get addresses() {
        return this.MemberManager.getAddresses()
    }

    get isAcceptingNewMembers() {
        return this.organization.isAcceptingNewMembers(!!SessionManager.currentSession?.user?.permissions)
    }

    async addMember() {
        const component = await createMemberComponent()
        if (component) {
            this.show(component)
        }
    }

    editMember(member: MemberWithRegistrations) {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(MemberView, { member })
        }).setDisplayStyle("popup"))
    }

    editParent(parent: Parent) {
        this.present(new ComponentWithProperties(ParentView, {
            parent,
            handler: async (parent: Parent, component: NavigationMixin) => {
                await MemberManager.patchAllMembersWith()
                component.pop({ force: true })
            }
        }).setDisplayStyle("popup"))
    }

    editAddress(address: Address) {
        this.present(new ComponentWithProperties(AddressView, {
            address,
            handler: async (address: Address, component: NavigationMixin) => {
                await MemberManager.patchAllMembersWith()
                component.pop({ force: true })
            }
        }).setDisplayStyle("sheet"))
    }
}
