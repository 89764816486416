




































import { NavigationMixin } from '@simonbackx/vue-app-navigation';
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";

import { ErrorBox } from '../errors/ErrorBox';
import STErrorsDefault from "../errors/STErrorsDefault.vue"
import LoadingButton from "../navigation/LoadingButton.vue"
import Spinner from "../Spinner.vue"
import { CenteredMessage, CenteredMessageButton } from './CenteredMessage';

@Component({
    components: {
        Spinner,
        STErrorsDefault,
        LoadingButton
    }
})
export default class CenteredMessageView extends Mixins(NavigationMixin) {
    @Prop({ required: true})
    centeredMessage: CenteredMessage

    isClosing = false
    errorBox: ErrorBox | null = null

    mounted() {
        this.centeredMessage.doHide = () => {
            this.close()
        }

        if (document.activeElement && (document.activeElement as any).blur) {
            (document.activeElement as any).blur();
        }
        setTimeout(() => {
            this.focusNextButton()
        }, 200)
    }

    onClickOutside() {
        // If this is a touch device, do nothing
        if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || ((navigator as any).msMaxTouchPoints > 0)) {
            return
        }
        this.dismiss();
    }

    async onClickButton(button: CenteredMessageButton) {
        if (this.isClosing) {
            return
        }
        if (button.loading) {
            return;
        }
        if (button.action) {
            button.loading = true;
            try {
                await button.action()
            } catch (e) {
                this.errorBox = new ErrorBox(e)
                button.loading = false
                return;
            }
            this.errorBox = null
            button.loading = false
        }
        this.close()
    }

    dismiss() {
        const closeButton = this.centeredMessage.buttons.find(b => b.type == "secundary")
        if (!closeButton) {
            return;
        }

        this.onClickButton(closeButton).catch(console.error)
    }

    close() {
        if (this.isClosing) {
            return
        }
        this.isClosing = true
        this.pop({ force: true })
    }

    activated() {
        document.addEventListener("keydown", this.onKey);
    }

    deactivated() {
        document.removeEventListener("keydown", this.onKey);
    }

    getButtons() {
        let buttons = this.$refs.buttons as any

        if (!buttons) {
            return [];
        }

        if (buttons.length === undefined) {
            buttons = [buttons]
        }
        return buttons;
    }

    focusNextButton() {
        console.log('focusNextButton')
        let buttons = this.getButtons()
        if (buttons.length == 0) {
            console.log('no buttons')
            return
        }

        // Find first focused button and select the next one or first one if it is the last one
        const focusedButton = buttons.findIndex((b: any) => b === document.activeElement)
        
        let button = buttons[0];
        if (focusedButton !== -1) {
            if (focusedButton >= buttons.length - 2) {
                button = buttons[0];
            } else {
                button = buttons[focusedButton+1]
            }
        }

        // Fix unreliable focus visible
        button.classList.add("focus-visible");

        // And we'll remove it again on blur, once
        button.addEventListener("blur", function () {
            button.classList.remove("focus-visible");
        }, { once: true });
        
        button.focus()
    }

    onKey(event) {
        if (event.defaultPrevented || event.repeat) {
            return;
        }

        const key = event.key || event.keyCode;
        const closeButton = this.centeredMessage.buttons.find(b => b.type == "secundary")

        if (key === "Tab") {
            this.focusNextButton();
            event.preventDefault();
            return;
        }

        if (key === "Escape" || key === "Esc" || key === 27) {
            if (!closeButton) {
                return;
            }

            this.onClickButton(closeButton).catch(console.error)
            event.preventDefault();
            return;
        }

        if (key === "Enter" || key === 13) {
            const focusedButton = this.getButtons().find((b: any) => b === document.activeElement)
            if (focusedButton) {
                // Browser default
                return;
            }
            // Do we have a default action?
            const defaultButton = this.centeredMessage.buttons.find(b => b.action !== null && b.type != "destructive")
            if (defaultButton) {
                this.onClickButton(defaultButton).catch(console.error)
                event.preventDefault();
                return;
            }
        }
    }
}
