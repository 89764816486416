




















































import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Formatter } from "@stamhoofd/utility"
import { Component, Mixins,Prop } from "vue-property-decorator";

import LongPressDirective from "../directives/LongPress";
import Dropdown from "../inputs/Dropdown.vue";
import ContextMenuView from "./ContextMenuView.vue";

@Component({
    components: {
        ContextMenuView,
        Dropdown
    },
    directives: {
        LongPress: LongPressDirective
    }
})
export default class DateSelectionView extends Mixins(NavigationMixin) {
    @Prop()
        setDate!: (date: Date | null) => void;

    @Prop()
        onClose!: () => void;

    @Prop({ default: true })
        autoDismiss!: boolean;

    @Prop({ required: true })
        selectedDay!: Date
    currentMonth: Date = new Date((this.selectedDay ?? new Date()).getTime())
    weeks: any = null
    monthTitle = ""
    yearTitle = ""

    @Prop({ default: false })
        allowClear!: boolean

    created() {
        this.weeks = this.generateDays()
        this.updateMonthTitle()
    }

    beforeDestroy() {
        this.onClose()
    }

    mounted() {
        if (!this.autoDismiss) {
            (this.$refs?.aside as any)?.addEventListener("mousedown", (e) => {
                e.preventDefault()
            });
        }
    }

    generateDays() {
        const weeks: any = [];

        const start = new Date(this.currentMonth.getTime())
        start.setDate(1)

        const month = start.getMonth()
        const year = start.getFullYear()

        // Make sure first day is 1
        while (start.getDay() != 1) {
            start.setDate(start.getDate() - 1)
        }

        // loop days
        while ((start.getMonth() <= month && start.getFullYear() == year) || start.getFullYear() < year || start.getDay() != 1) {
            if (start.getDay() == 1) {
                // Start new week
                weeks.push([])
            }

            weeks[weeks.length - 1].push({
                number: start.getDate(),
                value: new Date(start.getTime()),
                otherMonth: start.getMonth() != month,
                selected: this.selectedDay && start.getDate() == this.selectedDay.getDate() && start.getFullYear() == this.selectedDay.getFullYear() && start.getMonth() == this.selectedDay.getMonth()
            })

            start.setDate(start.getDate() + 1)

            if (weeks[weeks.length - 1].length > 7 || weeks.length > 6) {
                console.error("Calendar infinite loop")
                break;
            }
        }
        return weeks
    }

    clear() {
        this.setDate(null)
        this.pop();
    }

    setDateValue(date: Date) {
        const selectedDay = this.selectedDay
        selectedDay.setTime(date.getTime())
        this.currentMonth = new Date(selectedDay.getTime())
        this.weeks = this.generateDays()
        this.updateMonthTitle()
        this.setDate(new Date(date.getTime()))
    }

    setToday() {
        this.setDateValue(new Date())
        this.pop();
    }

    updateMonthTitle() {
        this.monthTitle = Formatter.capitalizeFirstLetter(Formatter.month(this.currentMonth.getMonth() + 1));
        this.yearTitle = this.currentMonth.getFullYear().toString()
    }

    nextMonth() {
        this.month = this.month + 1
    }

    updateSelectedMonth() {
        if (!this.selectedDay) {
            return
        }
        // Don't make a copy
        const selectedDay = this.selectedDay
        const day = selectedDay.getDate();
        selectedDay.setMonth(this.currentMonth.getMonth())
        selectedDay.setMonth(this.currentMonth.getMonth())
        selectedDay.setFullYear(this.currentMonth.getFullYear())
        if (selectedDay.getDate() < day) {
            selectedDay.setDate(new Date(selectedDay.getFullYear(), selectedDay.getMonth() + 1, 0).getDate())
        }
        this.setDate(selectedDay)
    }

    previousMonth() {
        const d = new Date(this.currentMonth)
        d.setDate(0)
        d.setDate(1)
        this.setDateValue(d)
    }

    nextYear() {
        this.currentYear = this.currentYear + 1
    }

    previousYear() {
        this.currentYear = this.currentYear - 1
    }

    onSelect(day) {
        day.selected = true;
        this.setDate(day.value)
        this.pop();
    }

    get nowYear() {
        const ny = new Date().getFullYear(); 

        if (this.currentYear < ny - 50) {
            return this.currentYear + 50
        }
        if (this.currentYear > ny) {
            return this.currentYear
        }
        return ny;
    }

    get currentYear() {
        return this.currentMonth.getFullYear();
    }

    set currentYear(year: number) {
        if (!year) {
            // Weird vue thing
            return;
        }
        const d = new Date(this.currentMonth)
        d.setFullYear(year)

        this.currentMonth = d
        this.updateSelectedMonth()
        this.updateMonthTitle();
        this.weeks = this.generateDays()
    }

    get month() {
        // Date is not reactive
        return this.currentMonth.getMonth() + 1;
    }

    set month(month: number) {
        if (!month) {
            // Weird vue thing
            return;
        }
        const d = new Date(this.currentMonth)
        d.setDate(1)
        d.setMonth(month - 1)

        this.currentMonth = d
        this.updateSelectedMonth()
        this.updateMonthTitle();
        this.weeks = this.generateDays()
    }

    monthText(month: number) {
        return Formatter.capitalizeFirstLetter(Formatter.month(month))
    }
}
