












import { Component, Prop, Vue } from "vue-property-decorator";
import draggable from 'vuedraggable'

@Component({
    components: {
        draggable
    }
})
export default class STList extends Vue {
    @Prop({ default: null })
        value!: any[] | null

    @Prop({ default: false })
        draggable!: boolean;

    @Prop({ default: undefined })
        group!: string | undefined;

    @Prop({ default: false })
        withAnimation!: boolean;

    dragging = false;

    get list() {
        return this.value;
    }

    set list(changed: any[] | null) {
        this.$emit('input', changed);
    }

    onStart() {
        this.dragging = true;
    }

    onEnd(event) {
        // On firefox we need to cancel all click events that happen after a drag
        // otherwise it will click one of the elements that was dragged

        setTimeout(() => {
            this.dragging = false;
        }, 100)
    }
}
