






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class CodeInput extends Vue {
    valid = true;

    @Prop({ default: "" })
    value!: string

    @Watch("value")
    onValueChanged(value: string, _oldValue: string) {
        if (value == _oldValue) {
            return
        }
        if (value == this.getInternalValue()) {
            return
        }
        if (!(this.$refs && this.$refs.numberInput && Array.isArray(this.$refs.numberInput))) {
            return
        }
        for (let index = 0; index < this.codeLength; index++) {
            const element = this.$refs.numberInput[index] as HTMLInputElement;
            
            if (index < value.length) {
                const letter = value[index]
                element.value = letter
            } else {
                element.value = ""
            }
        }
    }

    get codeLength() {
        return 6
    }

    mounted() {
        setTimeout(() => {
            this.selectNext(0)
        }, 300)
    }

    onInput(index: number) {
        if (!(this.$refs && this.$refs.numberInput && Array.isArray(this.$refs.numberInput))) {
            return
        }

        const input = this.$refs.numberInput[index] as HTMLInputElement;
        input.value = (input.value as string).replace(/[^0-9]/g, '')
        if (input.value.length >= 1) {
            this.selectNext(index + 1)
        }
    }

    clearInput(index: number, select = true) {
        if (!(this.$refs && this.$refs.numberInput && Array.isArray(this.$refs.numberInput))) {
            return
        }

        // Move everything one to the left
        const input = this.$refs.numberInput[index] as HTMLInputElement;
        if (input.value.length == 0 && index < this.codeLength - 1) {
            input.value = (this.$refs.numberInput[index + 1] as HTMLInputElement).value;
            (this.$refs.numberInput[index + 1] as HTMLInputElement).value = ""
            this.clearInput(index + 1, false)
        }

        if (select) {
            if (index > 0) {
                this.selectNext(index - 1)
            } else {
                // reselect
                this.selectNext(index)
            }
            this.updateValue()
        }
    }

    selectNext(index: number) {
        if (index < 0) {
            return
        }

        if (!(this.$refs && this.$refs.numberInput && Array.isArray(this.$refs.numberInput))) {
            return
        }

        console.log("select next ", index)
        if (index >= this.codeLength) {
            const prev = this.$refs.numberInput[index - 1] as HTMLInputElement;
            const val = prev.value;
            if (val.length > 1) {
                prev.value = val.substr(0, 1)
            }
            for (let index = 0; index < this.codeLength; index++) {
                const element = this.$refs.numberInput[index] as HTMLInputElement;
                element.blur()
            }
            this.updateValue()

            if (this.getInternalValue().length === this.codeLength) {
                this.$emit("complete")
            }
            return
        }
        if (index >= 1) {
            const prev = this.$refs.numberInput[index - 1] as HTMLInputElement;
            const val = prev.value;
            if (val.length > 1) {
                prev.value = val.substr(0, 1);
                (this.$refs.numberInput[index] as HTMLInputElement).value = val.substr(1)
                this.selectNext(index + 1)
                return
            }
        }
        (this.$refs.numberInput[index] as HTMLInputElement).focus();

        if ((this.$refs.numberInput[index] as HTMLInputElement).value.length > 0) {
            // iOS fix
            (this.$refs.numberInput[index] as HTMLInputElement).select();
        }
        this.updateValue()
    }

    getInternalValue() {
        if (!(this.$refs && this.$refs.numberInput && Array.isArray(this.$refs.numberInput))) {
            return ""
        }

        let val = ""
        for (let index = 0; index < this.codeLength; index++) {
            const element = this.$refs.numberInput[index] as HTMLInputElement;
            const letter = element.value.substr(0, 1).toUpperCase()
            val += letter
            if (letter.length == 0) {
                break
            }
        }
        return val
    }

    updateValue() {
        this.$emit("input", this.getInternalValue())
    }

}
