




































































import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { BackButton, Checkbox, STList, STListItem, STNavigationBar, STToolbar } from "@stamhoofd/components";
import { SessionManager } from "@stamhoofd/networking";
import { MemberWithRegistrations, Registration } from '@stamhoofd/structures';
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CheckoutManager } from "../../classes/CheckoutManager";
import { MemberManager } from "../../classes/MemberManager";
import { OrganizationManager } from "../../classes/OrganizationManager";
import MemberBox from "../../components/MemberBox.vue";
import GroupsView from "./GroupsView.vue";

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        Checkbox,
        BackButton,
        MemberBox
    }
})
export default class MemberChooseGroupsView extends Mixins(NavigationMixin){
    @Prop({ required: true })
        member!: MemberWithRegistrations

    CheckoutManager = CheckoutManager
    MemberManager = MemberManager
    showMore = false

    mounted() {
        if (this.categories.length === 0) {
            this.showMore = true
        }
    }

    getGroup(groupId: string) {
        return OrganizationManager.organization.groups.find(g => g.id === groupId)
    }

    imageSrc(registration: Registration) {
        const group = this.getGroup(registration.groupId)
        if (!group) {
            return null
        }
        return (group.settings.squarePhoto ?? group.settings.coverPhoto)?.getPathForSize(100, 100)
    }

    get tree() {
        return OrganizationManager.organization.getCategoryTree({
            maxDepth: 1, 
            admin: !!SessionManager.currentSession!.user!.permissions, 
            smartCombine: true, // don't concat group names with multiple levels if all categories only contain one group
            filterGroups: g => {
                const canRegister = this.member.canRegister(g, MemberManager.members ?? [], OrganizationManager.organization.meta.categories, CheckoutManager.cart.items);
                return !canRegister.closed || canRegister.waitingList
            }
        })
    }

    get fullTree() {
        return OrganizationManager.organization.getCategoryTree({maxDepth: 1, admin: !!SessionManager.currentSession!.user!.permissions, smartCombine: true})
    }

    get categories() {
        return this.tree.categories
    }

    get fullCategories() {
        return this.fullTree.categories
    }

    get visibleCategories() {
        if (this.showMore) {
            return this.fullCategories
        }
        return this.categories
    }

    get hasMore() {
        return !this.showMore && this.tree.getAllGroups().length !== OrganizationManager.organization.getGroupsForPermissions(SessionManager.currentSession?.user?.permissions).length
    }

    get hasLess() {
        return this.showMore && this.categories.length !== 0
    }

    showAll() {
        this.present({
            components: [
                new ComponentWithProperties(
                    NavigationController, {
                        root: new ComponentWithProperties(
                            GroupsView, {
                                member: this.member
                            }
                        )
                    }
                )
            ],
            modalDisplayStyle: "popup"
        })
    }
}
