























































































































































import { ArrayDecoder, Decoder } from '@simonbackx/simple-encoding';
import { ComponentWithProperties, NavigationController, NavigationMixin } from '@simonbackx/vue-app-navigation';
import { ErrorBox, LoadingButton, StepperInput, Steps, STErrorsDefault, STList, STListItem, STNavigationBar, STToolbar } from '@stamhoofd/components';
import { SessionManager, UrlHelper } from '@stamhoofd/networking';
import { BalanceItemCartItem, Group, MemberBalanceItem, RegisterItem } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Watch } from 'vue-property-decorator';

import { CheckoutManager } from '../../classes/CheckoutManager';
import { MemberManager } from '../../classes/MemberManager';
import { OrganizationManager } from '../../classes/OrganizationManager';
import { Suggestion, SuggestionBuilder } from '../../classes/SuggestionBuilder';
import GroupView from '../groups/GroupView.vue';
import ChooseMemberView from '../overview/register-flow/ChooseMemberView.vue';


@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        STErrorsDefault,
        LoadingButton,
        StepperInput
    },
    filters: {
        price: Formatter.price.bind(Formatter),
        priceChange: Formatter.priceChange.bind(Formatter),
        date: Formatter.date.bind(Formatter)
    }
})
export default class CartView extends Mixins(NavigationMixin){
    CheckoutManager = CheckoutManager

    loading = false
    errorBox: ErrorBox | null = null

    get cart() {
        return this.CheckoutManager.cart
    }

    get title() {
        if (this.cart.balanceItems.length > 0) {
            return "Winkelmandje"
        }
        return "Inschrijvingsmandje"
    }

    addItem() {
        this.present({
            components: [
                new ComponentWithProperties(NavigationController, {
                    root: new ComponentWithProperties(ChooseMemberView, {}),
                    fromCart: true
                })
            ],
            modalDisplayStyle: "popup"
        })
    }

    async goToCheckout() { 
        if (this.loading) {
            return
        }
        if (this.cart.isEmpty) {
            return
        }
        this.loading = true
        this.errorBox = null

        try {
            if (OrganizationManager.organization.meta.recordsConfiguration.financialSupport) {
                // Go to financial view
                const component = (await import(/* webpackChunkName: "FinancialSupportView" */ './FinancialSupportView.vue')).default;
                this.show(
                    new ComponentWithProperties(Steps, { 
                        root: new ComponentWithProperties(component, {}),
                        totalSteps: OrganizationManager.organization.meta.recordsConfiguration.freeContribution !== null ? 3 : 2
                    })
                );
            } else if(OrganizationManager.organization.meta.recordsConfiguration.freeContribution !== null) {
                // Go to financial view
                const component = (await import(/* webpackChunkName: "FinancialSupportView" */ './FreeContributionView.vue')).default;
                this.show(
                    new ComponentWithProperties(Steps, { 
                        root: new ComponentWithProperties(component, {}),
                        totalSteps: 2
                    })
                );
            } else {
                // Go to financial view
                const component = (await import(/* webpackChunkName: "FinancialSupportView" */ './PaymentSelectionView.vue')).default;
                this.show(
                    new ComponentWithProperties(Steps, { 
                        root: new ComponentWithProperties(component, {}),
                        totalSteps: 1
                    })
                );
            }
        } catch (e) {
            console.error(e)
            this.recalculate().catch(console.error)
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    imageSrc(item: RegisterItem): string | null {
        return (item.group.settings.squarePhoto ?? item.group.settings.coverPhoto)?.getPathForSize(100, 100) ?? null
    }

    openGroup(group: Group) {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(
                GroupView, {
                    group,
                    registerButton: false
                }
            )
        }).setDisplayStyle("popup"))
    }

    deleteItem(item: RegisterItem) {
        CheckoutManager.cart.removeItem(item)
        CheckoutManager.saveCart()
    }

    deleteBalanceItem(item: BalanceItemCartItem) {
        CheckoutManager.cart.removeBalanceItem(item)
        CheckoutManager.saveCart()
    }

    mounted() {
        UrlHelper.setUrl("/cart")

        this.recalculate().catch(e => {
            console.error(e)
        })
    }

    @Watch("cart.items")
    onCartChanged() {
        try {
            this.cart.calculatePrices(
                MemberManager.members ?? [], 
                OrganizationManager.organization.groups, 
                OrganizationManager.organization.meta.categories,
                OrganizationManager.organization.meta.registrationPaymentConfiguration
            )
        } catch (e) {
            // error in calculation!
            console.error(e)
        }
        CheckoutManager.saveCart()
    }

    async recalculate() {
        try {
            await CheckoutManager.recalculateCart(
                // Refresh on every open of the cart, but not if last full refresh was less than 10 seconds ago
                CheckoutManager.isLastFullRefetchOld(10)
            )
            this.errorBox = null
        } catch (e) {
            console.error(e)
            this.errorBox = new ErrorBox(e)
        }
    }

    get members() {
        return MemberManager.members ?? []
    }

    get suggestedRegistrations(): Suggestion[] {
        return SuggestionBuilder.getSuggestions(this.members)
    }

    startRegistrationFlow(suggestion: Suggestion) {
        this.present({
            components: [
                new ComponentWithProperties(NavigationController, {
                    root: suggestion.getComponent(),
                    fromCart: true
                })
            ],
            modalDisplayStyle: "popup"
        })
    }
}
