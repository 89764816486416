





































import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { BackButton, OrganizationLogo, STList, STListItem, STNavigationBar } from "@stamhoofd/components";
import { SessionManager } from "@stamhoofd/networking";
import { MemberWithRegistrations } from "@stamhoofd/structures";
import { Component, Mixins } from "vue-property-decorator";

import { MemberManager } from "../../../classes/MemberManager";
import { OrganizationManager } from "../../../classes/OrganizationManager";
import { createMemberComponent } from "../../members/details/createMemberComponent";
import MemberChooseGroupsView from "../../members/MemberChooseGroupsView.vue";

@Component({
    components: {
        STNavigationBar,
        OrganizationLogo,
        STList,
        STListItem,
        BackButton
    }
})
export default class ChooseMemberView extends Mixins(NavigationMixin){
    MemberManager = MemberManager

    get isAcceptingNewMembers() {
        return this.organization.isAcceptingNewMembers(!!SessionManager.currentSession?.user?.permissions)
    }

    get organization() {
        return OrganizationManager.organization
    }

    get members() {
        if (MemberManager.members) {
            return MemberManager.members
        }
        return []
    }

    selectMember(member: MemberWithRegistrations) {
        this.show(new ComponentWithProperties(MemberChooseGroupsView, {
            member
        }))
    }

    async addNewMember() {
        const component = await createMemberComponent()
        if (component) {
            this.show(component)
        }
    }
}
