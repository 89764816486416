



















import { Component, Prop,Vue } from "vue-property-decorator";

@Component({})
export default class STListItem extends Vue {
    @Prop({ default: 'article', type: String })
        elementName!: string;

    @Prop({ default: false, type: Boolean })
        selectable!: boolean;

    @Prop({ default: false, type: Boolean })
        disabled!: boolean;

    onClick(event) {
        const isDragging = this.$parent.$parent.$el.className.indexOf('is-dragging') !== -1;
        console.log(this.$parent.$el.className)
        if (isDragging) {
            console.log('canceled list item click because of drag');
            return;
        }
        this.$emit('click', event);
    }
}
