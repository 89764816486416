

















































import { SessionManager } from "@stamhoofd/networking";
import { Organization, Webshop, WebshopTicketType } from "@stamhoofd/structures";
import { Component, Prop, Vue } from "vue-property-decorator";

import Logo from "../icons/Logo.vue";
import { CenteredMessage } from "../overlays/CenteredMessage";

@Component({
    components: {
        Logo
    }
})
export default class LegalFooter extends Vue {
    @Prop({ required: true })
        organization!: Organization;

    @Prop({ required: false, default: null })
        webshop!: Webshop | null;

    get isLoggedIn() {
        return SessionManager.currentSession?.isComplete() ?? false
    }

    async logout() {
        if (!(await CenteredMessage.confirm('Wil je uitloggen?', 'Ja, uitloggen', 'Hiermee zal je worden afgemeld.'))) {
            return
        }
        SessionManager.currentSession?.logout()
    }

    get privacyUrl() {
        if (this.webshop && this.webshop.meta.policies.length > 0) {
            return null
        }
        if (this.organization.meta.privacyPolicyUrl) {
            return this.organization.meta.privacyPolicyUrl
        }
        if (this.organization.meta.privacyPolicyFile) {
            return this.organization.meta.privacyPolicyFile.getPublicPath()
        }
        return null
    }

    get policies() {
        return this.webshop?.meta.policies ?? []
    }

    get hasTickets() {
        return this.webshop?.meta.ticketType === WebshopTicketType.Tickets
    }

    get isWebshop() {
        return !!this.webshop
    }
}
