






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    model: {
        prop: 'checked',
        event: 'change'
    },
})
export default class Checkbox extends Vue {
    @Prop({ default: "", type: String })
    name!: string;

    @Prop({ default: false })
    checked!: boolean;

    @Prop({ default: false })
    onlyLine!: boolean;

    @Prop({ default: false })
    disabled!: boolean;

    // Set to true to only allow external changes
    @Prop({ default: false })
    manual!: boolean;

    get hasDefaultSlot() {
        return !!this.$slots.default
    }

    get checkboxValue() {
        return this.checked;
    }

    set checkboxValue(value) {
        this.$emit("change", value)

        // Add support for a model that doesn't change
        this.$nextTick(() => {
            if (this.checkboxValue != value) {
                if (this.$refs.checkbox) {
                    (this.$refs.checkbox as any).checked = this.checkboxValue;
                }
            }
        })
    }
}
